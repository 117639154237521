/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactDOM from 'react-dom';

import dayjs from 'dayjs';

import { alpha } from '@mui/material';

import { ChartOptions } from 'chart.js';

import { theme } from '../../../theme';

import { ComboChartTooltip } from './ComboChartTooltip/ComboChartTooltip';

export interface ComboData {
  labels: string[];
  datasets: ComboDataset[];
  maxBarValue: number;
}

export const comboBarsColors = {
  blue: '#367BF4',
  lightBlue: '#47C9FD',
  grey: '#9D9DBE'
};

export interface ComboDataset {
  type: 'bar' | 'line';
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  stack?: string;
  tension?: number;
  yAxisID?: string;
}

export const getComboOptions = (
  rawData: any,
  maxBarValue: number,
  handleRedirect: (route: string) => void
): ChartOptions<'line'> => ({
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: false,
      external: context => {
        let tooltipEl = document.getElementById('chartjs-tooltip');

        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.background = 'rgba(255, 255, 255, 0.9)';
          tooltipEl.style.borderRadius = '3px';
          tooltipEl.style.boxShadow = '0 0 15px rgba(0, 0, 0, 0.1)';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.transition = 'all .1s ease';
          document.body.appendChild(tooltipEl);
        }

        const tooltipModel = context.tooltip;

        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        if (tooltipModel.body) {
          const { dataIndex } = tooltipModel.dataPoints[0];
          const pointSchema = rawData[dataIndex];
          // const timeWindow = tooltipModel.title[0];

          (ReactDOM as any).render(<ComboChartTooltip pointSchema={pointSchema} />, tooltipEl);
        }

        const position = context.chart.canvas.getBoundingClientRect();

        tooltipEl.style.opacity = '1';
        tooltipEl.style.borderRadius = '12px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.top = position.top + window?.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.left = position.left + window?.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.padding = tooltipModel.options.padding + 'px ' + tooltipModel.options.padding + 'px';
        tooltipEl.style.left =
          position.left + window?.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth / 2 + 'px';
      }
    }
  },
  onClick: (_event, elements) => {
    if (elements.length > 0) {
      const tooltipEl = document.getElementById('chartjs-tooltip');

      if (tooltipEl) {
        tooltipEl.style.opacity = '0';
      }

      const dataIndex = elements[0]?.index;
      const endTime = rawData[dataIndex].end_time;
      const startTime = rawData[dataIndex].start_time;

      if (endTime && startTime && typeof window !== 'undefined') {
        handleRedirect(`/samples?start_time_epoch=${dayjs(startTime).unix()}&end_time_epoch=${dayjs(endTime).unix()}`);
      }
    }
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false
      },
      ticks: {
        maxRotation: 0,
        maxTicksLimit: 6,
        color: theme.palette.grey[500],
        font: {
          family: 'Manrope',
          size: 10,
          weight: 700
        }
      }
    },
    'left-y-axis': {
      type: 'linear',
      position: 'left',
      beginAtZero: true,
      grid: {
        display: Array?.isArray(rawData) && rawData?.every(val => val === null) ? false : true
      },
      ticks: {
        callback: value => (typeof value === 'number' ? `${(Number(value) * 100)?.toFixed(0)}%` : value),
        stepSize: 0.1,
        font: {
          family: 'Manrope'
        }
      }
    },
    'right-y-axis': {
      type: 'linear',
      position: 'right',
      beginAtZero: true,
      max: Math.round(maxBarValue * 1.5),
      grid: {
        display: false
      }
    }
  },
  animation: {
    duration: 500
  },
  spanGaps: true
});

export const getComboData = (
  barData: number[][],
  lineData: number[],
  labels: string[],
  barLegends: string[],
  maxBarValue: number
): ComboData => {
  const barColors = [comboBarsColors.blue, comboBarsColors.lightBlue, comboBarsColors.grey];

  const barDatasets = Array?.isArray(barData)
    ? barData?.[0]?.map((_, segmentIndex) => ({
        type: 'bar' as const,
        label: barLegends?.[segmentIndex] ?? '',
        data: barData.map(data => data[segmentIndex] || 0),
        backgroundColor: alpha(barColors[segmentIndex % barColors.length], 0.5),
        borderColor: barColors[segmentIndex % barColors.length],
        borderWidth: 0,
        stack: 'stack1',
        yAxisID: 'right-y-axis'
      }))
    : [];

  const lineDataset = {
    type: 'line' as const,
    label: 'Annotation',
    data: lineData,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    borderColor: theme.palette.primary.main,
    borderWidth: 3,
    pointRadius: 4,
    spanGaps: true,
    yAxisID: 'left-y-axis'
  };

  return {
    labels,
    datasets: [...(Array?.isArray(barDatasets) ? barDatasets : []), lineDataset],
    maxBarValue
  };
};
